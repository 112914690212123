$top-nav: 84px;
$top-nav-max: 85px;
$chat-header: 241px;
$chat-header: 241px;
$conversation-max :147px;

$primary-color: #7269ef;
$active-color: #645bd8;

//modern-style-colors
$active-mod-color: #5d87ff;
$active-mod-light-blue: #C7EBFF;
$active-mod-hover-color: #FEDCEC;
$active-mod-dark-color: #010715;
$active-mod-dark-color-d: #34609D ;
$mod-border-color: rgba(0, 0, 0, 0.08);
$mod-light-blue: #8AD7FF;
$mod-light-blue-D: #E3F5FF;
$mod-dark-blue: #34609D;
$mod-border-radius: 6px;
$mod-bubble-color: #c7ebff;


$primary-color: #10B0C1;
$active-color: #2DB1E6;

$background-color-01: #f5f7fb;
$background-color-02: #e6ebf5;
$background-hover: #7a7f9a;

$text-gray-1: #454545;

$text-light-gray-1: #a0a0a0;
$text-dark:#000000;

$thm-secondary:#005963;
$smylor-sky-blue: #66ccff;
$smylor-pink: #e040a0;
