.top-nav {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  background-color: #ffffff !important;
  height: #{$top-nav};
  color: $active-mod-dark-color !important;
  box-shadow: #C7EBFF 0 0 2px, #C7EBFF 0 2px 6px -4px !important;
  margin-bottom: 1px;

  .logo-container {
    height: 40px;
    cursor: pointer;
  }

  .home-icon {
    .fa-primary {
      fill: $mod-dark-blue !important;
      opacity: unset;
    }

    .fa-secondary {
      fill: $mod-light-blue !important;
      opacity: unset;
    }
  }
  .k-avatar-image{
    background-color: $active-mod-light-blue !important;
  }
  .k-avatar-md {
    width: 48px;
    height: 48px;
    flex-basis: 48px;
  }

  .k-avatar-solid.k-avatar-primary {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  .toggle-chat-menu {
    display: none;
  }

  .title {
    margin-right: 8px !important;
    font-weight: 500;
    font-size: 20px;
  }

  @media only screen and (max-width: 768px) {
    .toggle-chat-menu {
      padding-left: 8px;
      color: $mod-light-blue !important;

      .k-icon {
        font-size: 32px;
      }
    }

    .show {
      display: unset;
    }

    .title {
      margin-right: 8px !important;
    }
  }

  @media only screen and (max-width: 426px) {
    flex-flow: nowrap !important;

    // .logo-container {
    //   width: 120px !important;
    //   margin-right: 0px !important;
    // }

    .title {
      display: none;
      font-size: 18px !important;
    }
  }

  @media only screen and (max-width: 320px) {
    .logo-container {
      width: 80px !important;

    }

    .title {
      font-size: 14px !important;
    }
  }

  .patient-logout {
    .fa-primary {
      fill: $mod-dark-blue !important;
      opacity: unset;
    }

    .fa-secondary {
      fill: $mod-light-blue !important;
      opacity: unset;
    }
    .logout-button{
      border: 1px solid  $mod-light-blue !important;
      &.k-button:hover{
        background-color: $active-mod-light-blue !important;
      }
    }
    @media only screen and (max-width: 426px) {
      span {
        display: none;
      }
    }
  }
}