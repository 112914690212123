.no-data {
  width: fit-content;
  margin: 10rem auto 0;
  text-align: center;
  color: $text-light-gray-1;
  .no-data-icon {
    font-size: 6rem;
    margin-bottom: 1rem;
  }
  .no-data-title {
    font-size: 2rem;
  }
  .no-data-messaage {
    font-size: 1rem;
  }
}
