@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;500&display=swap');
@import './_01_variables';
@import './_02_core';

/** Start: overriding theme values */
$primary: $primary-color;

$button-bg: $primary-color;
$button-hovered-bg: $active-color;
$button-text: white;

@import "@progress/kendo-theme-default/dist/all.scss";
/** End: overriding theme values */

body {
    font-family: 'Roboto', sans-serif;
    // height: 100vh;
    margin: 0;
}

p {
    margin: 0;
}

h1 {
    margin-block-start: 0;
    margin-block-end: 0;
}

h4 {
    font-size: 1.3125rem;
    margin: 0;
}

h5 {
    font-size: 1.05rem;
}

.color-primary {
    color: $primary-color !important;
}

.d-flex {
    display: flex !important;
}

.d-block {
    display: block !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.bg-1 {
    background-color: $background-color-01 !important;
}

.bg-2 {
    background-color: $background-color-02 !important;
}

.text-center {
    text-align: center;
}

.text-muted {
    color: $text-light-gray-1;
}

.text-small {
    font-size: 12px;
}

.m-0 {
    margin: 0;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.w-100 {
    width: 100%;
}

.spinner {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.bg-1 {
    background-color: $background-color-01;
}

.c-pointer {
    cursor: pointer !important;
    display: flex;
    align-items: baseline;
    gap: 8px;
    padding-right: 8px;
    padding-top: 15px;
    // font-size: 24px;
    @media only screen and (max-width: 426px) {
        padding-top: 0px;
    }
}